<template>
  <div class="m-form-cashback" :class="formClasses">
    <form class="m-form-cashback__form" @submit.prevent="submitHandler" autocomplete="off">
      <div
        v-if="stateModel === EFormStates.SUCCESS"
        class="m-form-cashback__form-success m-form__success"
      >
        <div class="m-form__status-title">{{ props.successTitle }}</div>

        <div class="m-form__status-subtitle" v-html="props.successSubtitle"></div>

        <div class="m-form__status-button">
          <Button modifier="tertiary" theme="dark" @click="closeForm('success')">Закрыть</Button>
        </div>
      </div>

      <div
        v-else-if="stateModel === EFormStates.ERROR"
        class="m-form-cashback__form-error m-form__error"
      >
        <div class="m-form__status-title">{{ props.errorTitle }}</div>

        <div class="m-form__status-subtitle" v-html="props.errorSubtitle"></div>

        <div class="m-form__status-button">
          <Button modifier="tertiary" theme="dark" @click="closeForm('error')">Вернуться</Button>
        </div>
      </div>

      <div class="m-form-cashback__form-default m-form__default">
        <div class="m-form-cashback__form-labels">Ваши данные</div>

        <InputText
          v-model="formData.name"
          label="Имя*"
          theme="dark"
          placeholder="Введите имя"
          name="Имя"
          :valid="
            !v$.name.$invalid &&
            !errorsAggregator(v$.name.$errors, formBackendErrors).filter('name').length
          "
          :invalid="!!errorsAggregator(v$.name.$errors, formBackendErrors).filter('name').length"
          :errors="errorsAggregator(v$.name.$errors, formBackendErrors).filter('name')"
          @input="v$.name.$touch()"
          @change="v$.name.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <InputPhone
          v-model="formData.phone"
          :mask="{
            holdPlaceholder: {
              enable: true,
              placeholder: '+7 (___) ___-__-__',
            },
          }"
          placeholder="+7 (000) 000-00-00"
          label="Телефон*"
          theme="dark"
          :valid="
            !v$.phone.$invalid &&
            !errorsAggregator(v$.phone.$errors, formBackendErrors).filter('phone').length
          "
          :invalid="!!errorsAggregator(v$.phone.$errors, formBackendErrors).filter('phone').length"
          :errors="errorsAggregator(v$.phone.$errors, formBackendErrors).filter('phone')"
          @input="v$.phone.$touch()"
          @change="v$.phone.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputPhone>

        <InputText
          v-model="formData.email"
          label="Электронная почта*"
          theme="dark"
          type="email"
          placeholder="Введите электронную почту"
          name="Электронная почта"
          :valid="
            !v$.email.$invalid &&
            !errorsAggregator(v$.email.$errors, formBackendErrors).filter('email').length
          "
          :invalid="!!errorsAggregator(v$.email.$errors, formBackendErrors).filter('email').length"
          :errors="errorsAggregator(v$.email.$errors, formBackendErrors).filter('email')"
          @input="v$.email.$touch()"
          @change="v$.email.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <div class="m-form-cashback__form-labels">Ссылки на ваши отзывы</div>

        <InputText
          v-model="formData.links[0]"
          label="Ссылка*"
          theme="dark"
          placeholder="Введите ссылку на отзыв"
          name="Ссылка"
          :valid="
            !v$.links.$invalid &&
            !errorsAggregator(v$.links.$errors, formBackendErrors).filter('links.0').length
          "
          :invalid="
            !!errorsAggregator(v$.links.$errors, formBackendErrors).filter('links.0').length
          "
          :errors="errorsAggregator(v$.links.$errors, formBackendErrors).filter('links.0')"
          @input="v$.links.$touch()"
          @change="v$.links.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <InputText
          v-model="formData.links[1]"
          label="Ссылка"
          theme="dark"
          placeholder="Введите ссылку на отзыв"
        >
        </InputText>

        <InputText
          v-model="formData.links[2]"
          label="Ссылка"
          theme="dark"
          placeholder="Введите ссылку на отзыв"
        >
        </InputText>

        <div class="m-form-cashback__form-labels">Выберите способ получения кэшбэка</div>

        <div class="m-form-cashback__form-radio">
          <Radio
            v-model="formData.payment_type"
            name="radio"
            value="phone"
            theme="dark"
            custom-radio
            :error="v$.payment_type.$error"
            @input="v$.payment_type.$touch()"
            @change="
              v$.payment_type.$touch();
              formCheckboxHandler();
            "
          >
            На баланс телефона
          </Radio>

          <Radio
            v-model="formData.payment_type"
            name="radio"
            value="card"
            theme="dark"
            custom-radio
            :error="v$.payment_type.$error"
            @input="v$.payment_type.$touch()"
            @change="
              v$.payment_type.$touch();
              formCheckboxHandler();
            "
          >
            На карту
          </Radio>

          <span
            v-for="(error, i) in v$.payment_type.$errors"
            :key="`error-${i}`"
            class="m-form-cashback__radio-errors m-form__additional-errors"
          >
            {{ error.$message }}
          </span>
        </div>

        <Textarea
          v-model="formData.payment_requisites as string"
          theme="dark"
          :label="textareaPlaceholder"
          placeholder="Введите номер карты или номер телефона*"
          :rows="1"
          modifier="multilines"
          :valid="
            !v$.payment_requisites.$invalid &&
            !errorsAggregator(v$.payment_requisites.$errors, formBackendErrors).filter(
              'payment_requisites',
            ).length
          "
          :invalid="
            !!errorsAggregator(v$.payment_requisites.$errors, formBackendErrors).filter(
              'payment_requisites',
            ).length
          "
          :errors="
            errorsAggregator(v$.payment_requisites.$errors, formBackendErrors).filter(
              'payment_requisites',
            )
          "
          @input="v$.payment_requisites.$touch()"
          @change="v$.payment_requisites.$touch()"
          v-textarea-auto-height
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </Textarea>

        <div v-if="getGenericCaptchaState" class="m-form-captcha m-form-captcha--dark">
          <div class="m-form-captcha__header">Введите код с картинки</div>
          <div class="m-form-captcha__box">
            <img
              v-if="captchaImage"
              :src="captchaImage"
              alt="Введите код с картинки"
              title="Введите код с картинки"
              width="120px"
              height="50px"
            />

            <Button
              title="Обновить код капчи"
              aria-label="Обновить код капчи"
              @click="fetchCaptcha"
            >
              <i class="icon-main-reload"></i>
            </Button>
          </div>

          <InputText
            v-model="formData.captcha"
            label="Код*"
            theme="dark"
            placeholder="Введите код"
            name="captcha"
            :invalid="!!errorsAggregator(null, formBackendErrors).filter('captcha').length"
            :errors="errorsAggregator(null, formBackendErrors).filter('captcha')"
          >
            <template #errors="{ errors }">
              <span v-for="(error, i) in errors" :key="`error-${i}`">
                {{ error }}
              </span>
            </template>
          </InputText>
        </div>

        <div class="m-form__form-policy">
          <slot name="policyText">
            Нажимая на кнопку «Отправить», вы соглашаетесь с
            <a href="/docs/privacy_policy.pdf" target="_blank" class="link-dark">
              политикой обработки персональных данных
            </a>
          </slot>
        </div>

        <Button
          tag="button"
          type="submit"
          css-class="button"
          modifier="primary"
          :loading="stateModel === EFormStates.LOADING || stateModel != EFormStates.DEFAULT"
        >
          <template #loading>
            <Loader></Loader>
          </template>
          <slot name="buttonText">Отправить</slot>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
// vue
import { computed, ref } from 'vue';

// Components
import InputText from '@ice-products-ui/vue-library/InputText';
import InputPhone from '@ice-products-ui/vue-library/InputPhone';
import Radio from '@ice-products-ui/vue-library/Radio';
import Textarea from '@ice-products-ui/vue-library/Textarea';
import Button from '@ice-products-ui/vue-library/Button';
import Loader from '@ice-products-ui/vue-library/Loader';

// Directives
import vTextareaAutoHeight from '@ice-products-ui/vue-library/TextareaAutoHeight';

// Types
import type { IFormProps, IFormEmits } from '../form.types';
import type { IFormCashbackModel } from '~/components/M/Forms/MFormCashback/MFormCashback.model';
import type { IErrorsBackendInterface } from '~/utils/arrays/errorsAggregator';

// Vuelidate
import { useVuelidate } from '@vuelidate/core';
import validations from '../form.validation.messages';

// Models
import {
  EFormStates,
  EFormPaymentTypes,
  EFormStateMessages,
} from '~/components/M/Forms/form.types';
import { FormCashbackModel } from '~/components/M/Forms/MFormCashback/MFormCashback.model';
import { EFormNames } from '~/composables/molecules/useForms';

// Composables
import useApiBase from '~/composables/api/base/useApiBase';
import useApiCaptcha from '~/composables/api/captcha/useApiCaptcha';
import { useIceGenericRequest } from '#imports';

// Utils
import errorsAggregator from '~/utils/arrays/errorsAggregator';
import QueryBuilder from '~/utils/queries/query-builder';

const emit = defineEmits<IFormEmits<IFormCashbackModel>>();
const props = withDefaults(defineProps<IFormProps>(), {
  loading: false,
  successTitle: EFormStateMessages.SUCCESS_TITLE_DEFAULT,
  successSubtitle: EFormStateMessages.SUCCESS_SUBTITLE_CASHBACK,
  errorTitle: EFormStateMessages.ERROR_TITLE_DEFAULT,
  errorSubtitle: EFormStateMessages.ERROR_SUBTITLE_DEFAULT,
});

const { baseApiUrl } = useApiBase();
const { getGenericCaptchaState, captchaImage, captchaKey, fetchCaptcha } = await useApiCaptcha(
  EFormNames.FORM_CASHBACK,
);
const { getGenericContextsData } = useIceGenericRequest();

const stateModel = ref(EFormStates.DEFAULT);
const formData = ref(new FormCashbackModel({}, getGenericCaptchaState.value));
const formBackendErrors = ref<IErrorsBackendInterface | null>(null);
const textareaPlaceholder = ref('Номер телефона для пополнения*');

/**
 * @description Form classes
 */
const formClasses = computed(() => {
  return {
    'm-form--default': stateModel.value === EFormStates.DEFAULT,
    'm-form--loading': stateModel.value === EFormStates.LOADING,
    'm-form--success': stateModel.value === EFormStates.SUCCESS,
    'm-form--error': stateModel.value === EFormStates.ERROR,
  };
});

/**
 * @description Close form handler
 * @param {string} status
 */
const closeForm = (status: string) => {
  stateModel.value = EFormStates.DEFAULT;
  emit('update:state', EFormStates.DEFAULT);

  if (status === EFormStates.SUCCESS) {
    formData.value.reset();
    v$.value.$reset();
  }
};

/**
 * @description Form checkboxes handler
 */
const formCheckboxHandler = () => {
  v$.value.payment_requisites.$touch();

  textareaPlaceholder.value =
    formData.value.payment_type === EFormPaymentTypes.PHONE
      ? 'Номер телефона для пополнения*'
      : 'Номер карты или номер телефона*';
};

/**
 * @description Validation rules
 * @returns {Object}
 */
const rules = computed(() => {
  return {
    name: { required: validations.required() },
    phone: {
      required: validations.required(),
      minLength: validations.phoneMin(18),
      maxLength: validations.phoneMax(18),
    },
    email: { required: validations.required(), email: validations.email },
    links: [
      {
        required: validations.required(),
        url: validations.url,
      },
    ],
    payment_type: {
      requiredRadio: validations.required('Вы&nbsp;должны выбрать один из&nbsp;вариантов'),
    },
    payment_requisites: {
      requiredRequisites: validations.requiredRequisites(formData.value.payment_type),
    },
  };
});

const v$ = useVuelidate(rules, formData);

/**
 * @description Form submit handler
 */
const submitHandler = async () => {
  v$.value.$touch();

  if (v$.value.$invalid) return;

  stateModel.value = EFormStates.LOADING;

  if (getGenericCaptchaState.value) {
    formData.value.captcha_key = captchaKey.value;
  }

  const queryStr = new QueryBuilder({
    contexts: getGenericContextsData.value,
  })
    .clearQuery()
    .buildQueryString();

  await $fetch(`forms/cashback_payment?${queryStr}`, {
    baseURL: baseApiUrl,
    method: 'POST',
    body: formData.value,
    onResponseError: (context) => {
      if (context.response.status === 422) {
        formBackendErrors.value = context.response._data;
        stateModel.value = EFormStates.DEFAULT;
        fetchCaptcha();
        return;
      }

      stateModel.value = EFormStates.ERROR;
      emit('update:state', EFormStates.ERROR);
    },
  });

  stateModel.value = EFormStates.SUCCESS;
  emit('update:state', EFormStates.SUCCESS);

  await fetchCaptcha();
};
</script>
